import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import fetchHelper from "../helpers/fetchHelper";
import { useAuth } from "../store/Auth";
import {user_verification, update_profile} from "../constants/urls";
import Loader from "../components/Loader";
import Swal from "sweetalert2";
import { useOwnerStore } from "../store/OwnerStore";

export default function Summary({ owner_details, invoice_details }) {
    const [id_status, setIdStatus] = useState("Not Verified");
    const getAuth = useAuth((state) => state.auth);
    const [verification_data, setVerificationData] = useState({});
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const { ownerDetails, setMultipleOwnerDetails, reset, setSingleOwnerDetails } = useOwnerStore();
    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        id_number:"",
        id_type: "nin",
        profile_type: "individual",
    });


    const updateOwnerStore = (data) => {
        const updatedDetails = {
            firstName: data.first_name,
            middleName: data.middle_name,
            lastName: data.last_name,
            idType: data.id_type,
            idNumber: data.id_number,
            profileType:data.profile_type
        }
        setMultipleOwnerDetails(updatedDetails);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;  // Get the name and value from the input field
        setFormData((prevData) => ({
          ...prevData,  // Spread the previous state
          [name]: value,  // Update the specific field that triggered the change
        }));
    };

    const updateProfile = async() => {
        setUpdating(true);
        let url = update_profile + verification_data.id;
        let token = getAuth.token;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        };

        let data={
            first_name:formData?.first_name?.toUpperCase() || owner_details.firstName?.toUpperCase(),
            middle_name:formData?.middle_name?.toUpperCase() || owner_details.middleName?.toUpperCase(),
            last_name:formData?.last_name?.toUpperCase() || owner_details.lastName?.toUpperCase(),
            id_number:formData.id_number || owner_details?.idNumber,
            id_type: formData.id_type || owner_details.idType,
            profile_type: formData.profile_type || owner_details.profileType,
            email: owner_details?.email,
            phone: owner_details?.phone
          }
        const response = await fetchHelper(url, "PATCH", data, headers)
        if(response.statusCode == 0){
            Swal.fire({
                title: "Profile Updated Successfully",
                text: " ",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1C89DE",
              }).then(()=>{
                //update the ownerstore
                updateOwnerStore(data);
                setUpdating(false);
              });
        }else if( response.statusCode == 3){
            Swal.fire({
                title: "User not found",
                text: " ",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1C89DE",
              }).then(()=>{
                setUpdating(false);
              });
        }
        
    }
    const revalidateOwner = async() => {
        setLoading(true);
        let url = user_verification + owner_details.idType + "/" + formData.id_number;
        let token = getAuth.token;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        };

        const response = await fetchHelper(url, "GET", null, headers)
        if(response.statusCode == 0 && response.data.verification_status == true){
            window.location.href = "/receipt/" + invoice_details.transactionId;
        }else if(response.statusCode == 0 && response.data.verification_status == false){
         setIdStatus("Failed");
         setVerificationData(response.data);
         setLoading(false);
         Swal.fire({
            title: "Validation failed",
            text: " ",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(()=>{
            setLoading(false);
          });
        }else if(response.statusCode == 2 || response.data.message =="user already verified"){
            window.location.href = "/receipt/" + invoice_details.transactionId;

        }else{
            setIdStatus("Failed");
            setVerificationData(response.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        let token = getAuth.token;
        let url = user_verification + owner_details.idType + "/" + owner_details.idNumber;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        };

          const validate_user = async() =>{
            const response = await fetchHelper(url, "GET", null, headers)
            if(response.statusCode == 0 && response.data.verification_status == true){
                window.location = "/receipt/" + invoice_details.transactionId;
            }else if(response.statusCode == 0 && response.data.verification_status == false){
                setVerificationData(response.data);
                setIdStatus("Failed");
            }else if(response.statusCode == 2 && response.message =="user already verified"){
                window.location = "/receipt/" + invoice_details.transactionId;
            }else {
                setIdStatus("Failed");
                setVerificationData(response.data);
            }
            // setVehicleTypes(response.data); // Assuming 'data' contains the array of vehicle types
          }
          validate_user();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-screen bg-gray-100">
            <ToastContainer />
            <div className="max-w-4xl mx-auto py-4 px-4">
                <div>
                    <p className="p-4 rounded-lg text-xl mt-3 bg-gray-700 text-gray-100 font-semibold mb-3 md:text-2xl">
                        Vehicle Owner Validation
                    </p>
                </div>
                <p className="mt-3 mb-2 text-red-700">This ID <span className="font-bold">{owner_details.idNumber}</span> failed validation. <br />
                    <span className="font-bold"> Reason: </span>: {verification_data.id_name?.trim() == "" ? "The ID Number is not found.": "The name on the ID is not the same as the name provided."}
                </p>
                {/* Flex container with responsiveness */}
                <div className="flex flex-wrap gap-4">
                    <div className="flex-1 min-w-[250px]">
                        <label className="text-gray-700 block pb-1">
                            First Name
                        </label>
                        <input
                            className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            type="text"
                            name="first_name"
                            onChange={handleInputChange}
                            defaultValue={owner_details.firstName || ""}
                        />
                    </div>
                    <div className="flex-1 min-w-[250px]">
                    <label className="text-gray-700 block pb-1">
                            Middle Name
                        </label>
                        <input
                            className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            type="text"
                            name="middle_name"
                            onChange={handleInputChange}
                            defaultValue={owner_details.middleName || ""}
                        />
                    </div>
                    <div className="flex-1 min-w-[250px]">
                    <label className="text-gray-700 block pb-1">
                            Last Name
                        </label>
                        <input
                            className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            type="text"
                            name="last_name"
                            onChange={handleInputChange}
                            defaultValue={owner_details.lastName || ""}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap gap-4 mt-4">
                    <div className="flex-1 min-w-[250px]">
                        <label className="text-gray-700 block pb-1">
                            Profile Type
                        </label>
                        <select
                            className="border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            name="profile_type"
                            onChange={handleInputChange}
                            defaultValue={owner_details.profileType}
                        >
                            <option value="individual">Individual</option>
                            <option value="corporate">Organization</option>
                        </select>
                    </div>
                    <div className="flex-1 min-w-[250px]">
                        <label className="text-gray-700 block pb-1">
                            ID Type
                        </label>
                        <select
                            className="border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            name="id_type"
                            onChange={handleInputChange}
                            defaultValue={owner_details.idType}
                        >
                            {owner_details.profileType === "individual" && <option value="nin">NIN</option>}
                            {owner_details.profileType === "corporate" && 
                                <>
                                    <option value="tin">TIN</option>
                                    <option value="cac">CAC</option>
                                </>
                            }
                        </select>
                    </div>
                    <div className="flex-1 min-w-[250px]">
                        <label className="text-gray-700 block pb-1">
                            ID Number
                        </label>
                        <input
                            className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            type="text"
                            name="id_number"
                            onChange={handleInputChange}
                            defaultValue={owner_details.idNumber || ""}
                        />
                    </div>
                    <div className="flex-1 min-w-[250px]">
                        <label className="text-gray-700 block pb-1">
                            Name on ID
                        </label>
                        <input
                            className="uppercase border w-full border-secondary-500 rounded-xl h-12 bg-white p-3"
                            type="text"
                            name="id_number_name"
                            value={verification_data.id_name}
                            readOnly
                        />
                    </div>
                </div>
                <div className="w-full justify-start flex text-lg">
                    <div className="mr-4">
                        <button
                          className="flex rounded w-30 bg-blue-500 py-2 px-3 mt-4 text-gray-100"
                          onClick={updateProfile}
                        >
                         Update Profile {updating && <Loader />}
                        </button>
                    </div>
                    <div>
                        <button
                          className="flex rounded w-30 bg-blue-500 py-2 px-3 mt-4 text-gray-100"
                          onClick={revalidateOwner}
                        >
                         Revalidate {loading && <Loader />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
