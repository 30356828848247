import React, { useState, useEffect } from "react";
import { useAuth } from "../store/Auth";
import { useFormStep } from "../store/FormStepStore";
// import { jsPDF } from "jspdf";
import Loader from "../components/Loader";
import { useUserStore } from "../store/UserStore";
import { useInvoiceStore } from "../store/InvoiceStore";
import { number_format } from "../utils/number_format";
import { remita_public_key, remita_secret } from "../constants/keys";
import { Link } from "react-router-dom";
import { base, confirm_payment, remita_inline_url } from "../constants/urls";
import Swal from "sweetalert2";
import { vatPercentage } from "../constants/others";
import { useCompanyVehicleDetails } from "../store/CompanyVehicleStore";
import Summary from "./Summary";
import { useOwnerStore } from "../store/OwnerStore";

export default function PrePayment() {
  const step = useFormStep((state) => state.step);
  const getAuth = useAuth((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const owner = useUserStore((state) => state.userDetails);
  const invoice = useInvoiceStore((state) => state.invoiceDetails);
  const setInvoice = useInvoiceStore((state) => state.setInvoiceDetails);
  const [proceed_to_summary, setProceedToSummary] = useState(false);
  const { ownerDetails } = useOwnerStore();
  const clearVehicles = useCompanyVehicleDetails(
    (state) => state.clearAllItems
  );
  //import remita library
  useEffect(() => {
    const script = document.createElement("script");
    script.src = remita_inline_url;
    script.async = false;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const makePayment = () => {
    var paymentEngine = window.RmPaymentEngine.init({
      key: remita_public_key,
      processRrr: true,
      email: invoice.email,
      customerId: invoice.email,
      firstName: invoice.firstName,
      lastName: invoice.lastName,
      narration: invoice.narration,
      extendedData: {
        customFields: [
          {
            name: "rrr",
            value: invoice.rrr,
          },
        ],
      },
      onSuccess: function (response) {
        //Confirm payment if it is successful and return to the cmr page
        let token = getAuth.token;
        let url =
          base +
          confirm_payment +
          "cmrinfo1/" +
          invoice.transactionId +
          "/" +
          invoice.rrr;

        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);

        var requestOptions = {
          method: "GET",
          headers: headers,
          redirect: "follow",
        };

        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.data.Status === "01") {
              setInvoice({
                ...invoice,
                status: "Paid",
              });
              clearVehicles();
              // window.location = "/receipt/" + invoice.transactionId;
              // window.location="/summary/" + invoice.transactionId;
            } else {
              console.error("Payment error");
              // Swal.fire({
              //   title: "Payment Canceled...!",
              //   text: " ",
              //   icon: "info",
              //   confirmButtonText: "Ok",
              //   confirmButtonColor: "#1C89DE",
              // });
              // return;
            }
            setProceedToSummary(true);
          })
          .catch((error) => console.log("error", error));
      },
      onError: function (response) {
        console.log("callback Error Response", response);
         Swal.fire({
            title: "Error initiating payment",
            text: "Contact Customer support",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
              return;
        // if (response) window.location.href = "http://www.example.com/test";
      },
      onClose: function () {
        setProceedToSummary(true);
        // // // let token = getAuth.token;
        // // // let url =
        // // //   base +
        // // //   confirm_payment +
        // // //   "cmrinfo/" +
        // // //   invoice.transactionId +
        // // //   "/" +
        // // //   invoice.rrr;
        // // // var headers = new Headers();
        // // // headers.append("Authorization", "Bearer " + token);
        // // // var requestOptions = {
        // // //   method: "GET",
        // // //   headers: headers,
        // // //   redirect: "follow",
        // // // };
        // // // fetch(url, requestOptions)
        // // //   .then((response) => response.json())
        // // //   .then((result) => {
        // // //     console.log(result);
        // // //     if (result.data.Status === "01") {
        // // //       // Swal.fire({
        // // //       //   title: "Payment Successful",
        // // //       //   text: " ",
        // // //       //   icon: "success",
        // // //       //   confirmButtonText: "Ok",
        // // //       //   confirmButtonColor: "#1C89DE",
        // // //       // });
        // // //     } else {
        // // //       // Swal.fire({
        // // //       //   title: "Payment Canceled...!",
        // // //       //   text: " ",
        // // //       //   icon: "info",
        // // //       //   confirmButtonText: "Ok",
        // // //       //   confirmButtonColor: "#1C89DE",
        // // //       // });
        // // //       return;
        // // //     }
        // //   })
        //   .catch((error) => console.log("error", error));
      },
    });
    paymentEngine.showPaymentWidget();
  };

  if (getAuth.authenticated === false && getAuth.token === "") {
    window.location = "/signup";
    return;
  }

  if (step < 6) {
    window.location = "/signup";
    return;
  }

  return (
    <div>
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      { !proceed_to_summary ? <div className="px-4 pt-8 bg-gray-100">
        <div className="flex justify-center items-center mb-10">
          <div className="">
            <p className="px-4 py-2 rounded-lg text-lg mt-3 font-bold bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
              Payment Invoice
            </p>
            {/* <a className="bg-blue-400 text-gray-100 rounded px-2">Invoice</a> */}
            <Link
              to={"/invoice/" + invoice.transactionId}
              target="_self"
              className="bg-blue-400 text-gray-100 rounded px-2 py-1"
            >
              View Invoice
            </Link>
            <div className="md:flex leading-1 lg:leading-loose">
              <div className="flex-1">
                <div className="flex">
                  <div className="flex w-32 font-bold">Full Name:</div>
                  <div className="flex-1">
                    {invoice.firstName} {invoice.middleName} {invoice.lastName}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex w-32 font-bold">Address:</div>
                  <div className="flex-1">{invoice.address}</div>
                </div>

                <div className="flex">
                  <div className="flex w-32 font-bold">Phone Number:</div>
                  <div className="flex-1">{invoice.phone}</div>
                </div>

                <div className="flex">
                  <div className="flex w-32 font-bold">Email Address:</div>
                  <div className="flex-1">{invoice.email}</div>
                </div>
              </div>

              <div className="bg-gray-100 flex-1 border-2 border-solid border-red-800 p-3 rounded-lg">
                <div className="flex">
                  <div className="flex w-32 font-bold">Invoice Number:</div>
                  <div className="flex-1">CI{invoice.invoiceNumber}</div>
                </div>
                <div className="flex">
                  <div className="flex w-32 font-bold">Transaction Ref:</div>
                  <div className="flex-1">{invoice.transactionId}</div>
                </div>
                <div className="flex">
                  <div className="flex w-32 font-bold">Narration:</div>
                  <div className="flex-1">{invoice.narration}</div>
                </div>
                <div className="flex">
                  <div className="flex w-32 font-bold">Amount:</div>
                  <div className="flex-1">
                    N
                    {number_format(
                      parseInt(invoice.amount) +
                        vatPercentage * parseInt(invoice.amount)
                    )}{" "}
                    (VAT Inclusive)
                  </div>
                </div>
                <div className="flex">
                  <div className="flex w-32 font-bold">Payment Ref:</div>
                  <div className="flex-1 font-bold">{invoice.rrr}</div>
                </div>
                <div className="w-full justify-end flex text-lg">
                  <div>
                    <button
                      onClick={() => {
                        makePayment();
                      }}
                      className="flex rounded w-30 bg-blue-400 py-2 px-3 mt-4 text-gray-100"
                    >
                      Make Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <Summary owner_details={ownerDetails} invoice_details={invoice}/>}
      
    </div>
  );
}
