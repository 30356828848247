import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PhoneAndEmailConfirmation from "./pages/PhoneAndEmailConfirmation";
import SelectServices from "./pages/SelectServices";
import UserDetails from "./pages/UserDetails";
import NoMatch from "./pages/NoMatch";
import InputVehicleDetails from "./pages/InputVehicleDetails";
// import Signup from "./pages/Signup";
import Login from "./pages/Login";
import TokenConfirmation from "./pages/TokenConfirmation";
import ResetPassword from "./components/ResetPassword";
import PrePayment from "./pages/PrePayment";
import InvoiceDetails from "./pages/InvoiceDetails";
import UserCMRRequestsList from "./components/UserCMRRequestsList";
import UserProfile from "./components/UserProfile";
import Reciept from "./pages/Reciept";
import Certificate from "./components/Certificate";
import Request from "./pages/Request";
import CertificateCheck from "./pages/CertificateCheck";
import OrganizationProfile from "./pages/OrganizationProfile";
import CreateRepProfile from "./pages/CreateRepProfile";
import EditRequest from "./pages/EditRequest";
import MySoldVehicles from "./pages/MySoldVehicles";
import AgentVehicleSearch from "./pages/AgentVehicleSearch";
import ChangeofOwnership from "./pages/ChangeofOwnership";
import ChangeofPlate from "./pages/ChangeofPlate";
import CowCertificate from "./pages/CowCertificate";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import ChangeofPlateConfirmation from "./pages/ChangeofPlateConfirmation";
import Owner from "./pages/Owner";
import AgentProfile from "./pages/AgentProfile";
import AgentNoify from "./pages/NotifyAgent";
import Cert from "./components/Cert";
import ProfilePage from "./pages/ProfilePage";
import Dashboard from "./pages/Dashboard";
import AgentConfirmToken from "./pages/AgentConfirmToken";
import Summary from "./pages/Summary";

function App() {
  // const User = ({ profile_type }) => {
  //   if (profile_type === "individual" || profile_type === "personal") {
  //     return (
  //       <>
  //         <IndividualProfile />
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <OrganizationProfile />
  //       </>
  //     );
  //   }
  // };

  return (
    <div className="">
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route
            path="/confirmation/:source"
            element={<PhoneAndEmailConfirmation />}
          />
          <Route path="/token/:source" element={<TokenConfirmation />} />
          <Route path="/agent/" element={<AgentProfile />} />
          <Route path="/organization" element={<OrganizationProfile />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<SelectServices />} />
          <Route path="/viewrequest/:reference" element={<Request />} />
          <Route path="/invoice/:id" element={<InvoiceDetails />} />
          <Route path="/passwordreset" element={<ResetPassword />} />
          <Route
            path="/services/vehicleinformation"
            element={<InputVehicleDetails />}
          />
          <Route path="/owner" element={<Owner />} />
          <Route path="/requests" element={<UserCMRRequestsList />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/invoice" element={<PrePayment />} />
          <Route path="/receipt/:id" element={<Reciept />} />
          <Route path="/certificate/:ref" element={<Certificate />} />
          <Route path="/cert/:id" element={<Cert />} />
          <Route path="/verify/:product/:id" element={<CertificateCheck />} />
          <Route path="/representative" element={<CreateRepProfile />} />
          <Route path="/review/:id" element={<EditRequest />} />
          <Route path="/mysoldvehicles" element={<MySoldVehicles />} />
          <Route path="/agentvehiclesearch" element={<AgentVehicleSearch />} />
          <Route path="/agentconfirmtoken" element={<AgentConfirmToken />} />
          <Route path="/changeofownership" element={<ChangeofOwnership />} />
          <Route path="/changeofplate" element={<ChangeofPlate />} />
          <Route
            path="/changeofplate/confirmation"
            element={<ChangeofPlateConfirmation />}
          />
          <Route path="/cowcertificate" element={<CowCertificate />} />
          <Route path="/confirmpayment" element={<PaymentConfirmation />} />
          <Route path="*" element={<NoMatch />} status={404} />
          <Route path="/agent_notify" element={<AgentNoify />} status={200} />
          <Route path="/dashboard" element={<Dashboard />} status={200} />
          <Route path="/summary" element={<Summary />} status={200} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
